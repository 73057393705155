<template lang='pug'>
head-meta(title-page="Мой кабинет" )
the-user-form
the-user-orders
</template>

<script>
import { getUser } from '../assets/scripts/Api'
import TheUserOrders from '../components/user-info/TheUserOrders'
import TheUserForm from '../components/user-info/TheUserForm'
import HeadMeta from '../components/_layout/HeadMeta'
export default {
  name: 'UserInfoPage',
  components: { HeadMeta, TheUserForm, TheUserOrders },
  async beforeRouteEnter (to, from, next) {
    const user = await getUser()
    if (!user.is_auth) return next({ name: 'Login' })
    next()
  }
}
</script>

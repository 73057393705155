<template lang='pug'>
.user-orders
  .user-orders__cont.cont
    h2.user-orders__title.title Заказы
    .user-orders__list(v-if="orders.length")
      .user-orders__item(v-for="order in orders", :key="order.id")
        .user-orders__head
          span.user-orders__number.headline №{{ order.id }}
          span.user-orders__date.text-small {{ order.date }}
        .user-orders__body
          .user-orders__product(v-for="(product, i) in order.products", :key="i")
            picture.user-orders__picture
              source(:srcset="`${product.image.w62} 1x, ${product.image.w124} 2x`", type="image/webp", media="(max-width: 700px)")
              source(:srcset="`${product.image.w87} 1x, ${product.image.w174} 2x`", type="image/webp", media="(max-width: 1240px)")
              source(:srcset="`${product.image.w87} 1x, ${product.image.w131} 1.5x`", type="image/webp")
              source(:srcset="`${product.image.j87} 1x, ${product.image.j131} 1.5x`")
              img.user-orders__image(:src="product.image.j87", :alt="`${product.name} - фото`", loading="lazy")
            .user-orders__info
              .user-orders__name-box
                router-link.user-orders__name.link_black-orange(
                    :to="{ name: 'Product', params: { slug: product.slug }}"
                  ) {{ product.name }}
                span.user-orders__description.text-small(v-if="product.description" ) {{product.description}}
              .user-orders__price-box
                span.user-orders__price.text(v-if="product.price" ) {{product.price.toLocaleString()}} ₽
                span.user-orders__quantity.text-tiny(v-if="product.quantity" ) {{product.quantity.toLocaleString()}} шт.
        .user-orders__total
          span.user-orders__total-text.text-small Итого
          span.user-orders__total-sum.headline {{order.sum.toLocaleString()}} ₽
    template(v-else)
      p.user-orders__text.text-big
        | Здесь пока пусто
        br
        | Выберите товары из корзины или найдите нужное в поиске
      .user-orders__link-box
        router-link.user-orders__link.button-white(:to="{name: 'Home'}") На главную
</template>

<script>
import '@/assets/styles/components/user-orders.sass'
import { getBasketHistory } from '../../assets/scripts/Api'

export default {
  name: 'TheUserOrders',
  data () {
    return {
      orders: []
    }
  },
  methods: {
    async getOrders () {
      const res = await getBasketHistory()
      return res.orders || []
    }
  },
  async mounted () {
    this.orders = await this.getOrders()
  }
}
</script>

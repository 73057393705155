<template lang='pug'>
.user-form
  .user-form__cont.cont
    h1.user-form__caption.caption Мой кабинет
    h2.user-form__title.title Информация о себе
    p.basket-form__text Указывайте реальные данные, чтобы менеджер мог связаться с вами и уточнить детали заказа.
    form.user-form__form(@submit.prevent="sendForm")
      .user-form__input-box
        label.user-form__label.text(for="user-name") Имя
        input.user-form__input.input.text(
          v-model="name",
          id="user-name",
          type="text",
          placeholder="Имя",
          @input="changeForm"
          :class="{'input_wrong' : error.name}"
        )
        span.user-form__error-text.text-error(v-if="error.name") {{error.name}}
      .user-form__input-box
        label.user-form__label.text(for="user-phone") Телефон
        input.user-form__input.input.text(
          v-model="phone",
          id="user-phone",
          type="tel",
          pattern="[0-9-+() ]*",
          placeholder="7 900 000 00 00",
          @input="changeForm",
          :class="{'input_wrong' : error.phone}"
        )
        span.user-form__error-text.text-error(v-if="error.phone") {{error.phone}}
      .user-form__input-box
        label.user-form__label.text(for="user-email") Почта
        input.user-form__input.input.text(
          v-model="email",
          type="email"
          id="user-email",
          placeholder="mail@mail.ru",
          @input="changeForm",
          :class="{'input_wrong' : error.email}"
        )
        span.user-form__error-text.text-error(v-if="error.email") {{error.email}}
      .user-form__input-box(:class="{ 'user-form__input-box_password' : isHiddenPassword }")
        label.user-form__label.text(for="oldPassword") Пароль
        button.user-form__change.link_orange-underline.text-small(v-if="isHiddenPassword", type="button", @click="showPasswordField") Изменить пароль
        .user-form__input-wrap
          input.user-form__input.user-form__input_password.input.text(
            v-if="!isHiddenPassword",
            type="password",
            id="oldPassword",
            minlength="8",
            v-model="oldPassword",
            placeholder="*************"
            required=true,
            autocomplete="off",
            @input="changeForm"
            :class="{'input_wrong' : error.oldPassword}"
          )
          span.user-form__error-text.text-error(v-if="error.oldPassword") {{error.oldPassword}}
      .user-form__input-box(v-if="!isHiddenPassword")
        label.user-form__label.text(for="password") Новый пароль*
        .user-form__input-wrap
          input.user-form__input.user-form__input_password.input.text(
            type="password",
            id="password",
            minlength="8",
            :class="{'input_wrong' : error.password}",
            v-model="password",
            placeholder="*************"
            required=true,
            autocomplete="off",
            @input="changeForm"
          )
          span.user-info__input-text.text-small Минимум — 8 символов
          span.user-form__error-text.text-error(v-if="error.password") {{error.password}}
      .user-form__buttons
        template(v-if="isEdit")
          button.user-form__save.button-orange.text(:disabled="isDisabled") Сохранить изменения
          button.user-form__reset.text.link_black-orange(type="button", @click="cleanForm") Отменить
        button.user-form__logout.button-orange.text(v-else, type="button", @click="logout") Выйти из личного кабинета
</template>

<script>
import { logout, refreshUser, getUser, updateUser } from '../../assets/scripts/Api'
import '@/assets/styles/components/user-form.sass'
export default {
  name: 'TheUserForm',
  data () {
    return {
      user: null,
      name: null,
      email: null,
      phone: null,
      oldPassword: null,
      password: null,
      isHiddenPassword: true,
      isEdit: false,
      isDisabled: true,
      isValidPassword: false,
      error: {}
    }
  },
  methods: {
    async logout () {
      const { status } = await logout()
      if (status === 'success') {
        await refreshUser()
        await this.$router.push({ name: 'Login' })
      }
    },
    showPasswordField () {
      this.isEdit = true
      this.isHiddenPassword = false
    },
    changeForm () {
      this.isEdit = true
      if (this.name !== this.user.name) this.isDisabled = false
      else if (this.email !== this.user.email) this.isDisabled = false
      else if (this.phone !== this.user.phone) this.isDisabled = false
      else if (this.password.length > 7) {
        this.isDisabled = false
        this.isValidPassword = false
      } else {
        this.isDisabled = true
        this.isValidPassword = true
      }
    },
    cleanForm () {
      this.name = this.user.name
      this.email = this.user.email
      this.phone = this.user.phone
      this.password = ''
      this.isDisabled = true
      this.isEdit = false
      this.isHiddenPassword = true
    },
    async sendForm () {
      const data = await updateUser(this.email, this.name, this.phone, this.oldPassword, this.password)
      this.isShowMessage = true
      if (data.status === 'success') {
        this.user = data.user
        this.error = {}
        this.cleanForm()
      } else {
        this.error = data.error
      }
    }
  },
  async mounted () {
    this.user = await getUser()
    this.cleanForm()
  }
}
</script>
